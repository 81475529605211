import React, { useState, useEffect } from "react";
import "./WhatsappBtn.css";

// The Whatsapp button is at bottom
function WhatsappBtn() {
  const [moveButton, setMoveButton] = useState(false);
  const url = "https://wa.me/918368681769";

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setMoveButton(true);
      } else {
        setMoveButton(false);
      }
    });
  }, []);

  const showWaChat = () => {
    window.open(url);
  };
  if (moveButton === true) {
    return (
      <>
        <button
          type="button"
          onClick={showWaChat}
          className="wa-btn-lg"
          id="btn-wa"
        >
          <i class="fab fa-whatsapp"></i>
        </button>
      </>
    );
  } else {
    return (
      <>
        <button
          type="button"
          onClick={showWaChat}
          className="d-wa-btn-lg"
          id="btn-wa"
        >
          <i class="fab fa-whatsapp"></i>
        </button>
      </>
    );
  }
}
export default WhatsappBtn;

//   var phone = "1231231231";
//   var message = "";
//   var size = "70px";
//   var backgroundColor = "#25D366";
//   var position = "right";
//   var popupMessage = "Hello, how can we help you?";
//   var showPopup = false;
//   var showOnIE = true;
//   var headerColor = "#128C7E";
//   var headerTitle = "WhatsApp Chat";

//   //const autoOpenTimeout = 10000;

//   const [openPopup, setOpenPopup] = useState(showPopup);
//   const [messageInput, setMessageInput] = useState(message);
//   const floatingWppButtonClicked = () => {
//     setOpenPopup(true);
//   };

//   //  [po]

//   //   const isMobile = () => {
//   //     if (
//   //       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//   //         navigator.userAgent
//   //       )
//   //     )
//   //       return true;
//   //     return false;
//   //   };

//   //   const isInternetExplorer = () => {
//   //     const userAgent = navigator.userAgent;
//   //     return (
//   //       userAgent &&
//   //       (userAgent.indexOf("MSIE") >= 0 || userAgent.match(/Trident.*rv:11\./))
//   //     );
//   //   };

//   const sendWhatsappMessage = () => {
//     var apilink = "https://";
//     // apilink += isMobile() ? "api" : "web";
//     apilink +=
//       "web.whatsapp.com/send?phone=" +
//       phone +
//       "&text=" +
//       encodeURI(messageInput);
//     window.open(apilink);
//   };

//   return (
//     <>
//       <div
//         className="floatingWppButton"
//         style={{
//           height: size,
//           width: size,
//           backgroundColor: backgroundColor,
//         }}
//         onClick={() => {
//           floatingWppButtonClicked();
//         }}
//       >
//         <img
//           src="images/Battery-Management-System.jpg"
//           alt=""
//           height="100px"
//           width="100px"
//         />
//       </div>
//       <div className={` ${openPopup ? "active" : ""} "floatingWppPopup" `}>
//         <div
//           className="floatingWppHead"
//           style={{ backgroundColor: headerColor }}
//         >
//           <span>{headerTitle}</span>
//           <strong className="close" onClick={() => setOpenPopup(false)}>
//             &times;
//           </strong>
//         </div>
//         <div className="floatingWppMessage">{popupMessage}</div>
//         <div className="floatingWppInputMessage">
//           <input type="text" />
//           {/* <textarea
//             value={messageInput}
//             onChange={(e: React.FormEvent<HTMLTextAreaElement>): void =>
//               setMessageInput(e.currentTarget.value)
//             }
//           /> */}
//           <div
//             className="floatingWppBtnSend"
//             onClick={() => sendWhatsappMessage()}
//           >
//             <img src="/images/bs.png" alt="" />
//           </div>
//         </div>
//       </div>
//     </>
//   );
