import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Paper,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import React from "react";
import { Button } from "./Button";
import "./CareerSec.css";
import { makeStyles } from "@material-ui/core";

export default function Careers() {
  const [experience, setExperience] = React.useState("");
  const [age, setAge] = React.useState("");

  function handleSubmit(e) {
    e.preventDefault();
    console.log("Submit Button is pressed");
  }

  const handleChange = (event) => {
    setExperience(event.target.value);
    setAge(event.target.value);
  };

  return (
    <>
      <div className="main-container">
        <h1 className="careers">Careers</h1>
        <h2 className="sub-heading">
          {" "}
          Join our growing team at <span className="blue-text">ZipBolt</span>
        </h2>
        <div className="">
          {/* <h2>What We Offer</h2> */}
          <Button
            id="cf-op-button"
            buttonStyle="btn--outline--Secondary"
            buttonSize="btn--large"
            onClick={console.log("Open Position Button Clicked")}
          >
            See Open Positions
          </Button>
        </div>

        <div>
          <div className="container-row">
            <Box
              className="CareerFormBox"
              sx={{
                display: "inline-grid",
                columnGap: 40,
                rowGap: 40,
                gridTemplateColumns: "repeat(2, 2fr)",
              }}
            >
              <TextField
                id="outlined-basic-required"
                label="First Name"
                variant="standard"
              />
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="standard"
              />
              <TextField
                id="outlined-basic"
                label="E mail"
                variant="standard"
              />
              <TextField
                id="outlined-basic"
                label="Mobile No."
                variant="standard"
              />
              {/* <div>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Experience
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                    label="Experience"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              <FormControl
                required
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-required-label">
                  Experience
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={experience}
                  onChange={handleChange}
                  label="Experience"
                >
                  <MenuItem value="Fresher">Fresher</MenuItem>
                  <MenuItem value="1 month">1 Month</MenuItem>
                  <MenuItem value="2 month">2 Months</MenuItem>
                  <MenuItem value="3 month +">3 Months +</MenuItem>
                  <MenuItem value="6 month +">6 Months +</MenuItem>
                  <MenuItem value="1 year +">1 Year +</MenuItem>
                  <MenuItem value="2 year +">2 Year +</MenuItem>
                  <MenuItem value="3 year +">3 Year +</MenuItem>
                </Select>
              </FormControl>

              <label
                style={{
                  borderBottom: "1px solid #949494",
                  color: "#757575",
                  cursor: "pointer",
                }}
              >
                <input
                  type="file"
                  id="test"
                  name="resume-upload"
                  accept=".pdf,.doc,.docx"
                  style={{ display: "none" }}
                />
                Click to Select Resume
              </label>
            </Box>

            <div className="Cf-submit">
              <label htmlFor="resume-upload">
                <input
                  className="Cf-submit-button"
                  type="submit"
                  value="SUMBIT"
                  variant="outlined"
                  buttonSize="btn--large"
                  onClick={handleSubmit}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
