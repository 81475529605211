import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../App.css";
import { Button } from "../Button";

export default function Products() {
  return (
    <>
      <Container fluid className="p-info-container-light">
        <Container>
          <Row>
            <Col>
              <Row className="layout-row-space-between layout-row-middle">
                <Col className="p-main-section">
                  <Row>
                    <Col md={6} className="p-heading-text">
                      <Col className="p-heading">
                        <h1 className="p-heading-title">
                          {" "}
                          CHARGING{" "}
                          <span className="p-heading-title-b">STATIONS</span>
                        </h1>
                      </Col>
                      <Col className="p-heading-subtitle">
                        Our patented technology provides{" "}
                        <span className="blue-highlighted-text">
                          {" "}
                          <br />
                          Electric Vehicle{" "}
                        </span>{" "}
                        charging solution
                      </Col>
                      <Col className="p-header-btn">
                        <Button
                          buttonStyle="btn--outline--Secondary"
                          buttonSize="btn--large"
                          onClick={console.log("hey")}
                        >
                          Demo Button
                        </Button>
                      </Col>
                    </Col>
                    <Col md={6} className="">
                      <img
                        className="main-charging-img"
                        src="/images/ZipBolt-EV.png"
                        alt="ZipBolt-4-vehicles"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="p-info-container-dark">
        <Container>
          <Row>
            <Col sm={12} md={6} className="p-img-col p-img-col-1">
              <img src="/images/13312002.png" alt="Eco-friendly Zipbolt" />
            </Col>
            <Col sm={12} md={6} className="p-info-section-title">
              <span className="blue-highlighted-text">EV charging</span> is now
              easy as charging your{" "}
              <span className="blue-highlighted-text">Smartphone</span>.
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="p-info-container-light">
        <Container>
          <Row>
            <Col sm={12} md={6} className="p-info-section-">
              <Row className="p-info-section-title-d">
                <Col>
                  <span className="blue-highlighted-text"> EVs </span> for more{" "}
                  <span className="blue-highlighted-text">greener</span> and
                  <span className="blue-highlighted-text">
                    {" "}
                    sustainable
                  </span>{" "}
                  future
                </Col>
              </Row>
              <Row className="p-info-section-text">
                <Col>
                  <p>
                    Conventional cars use internal combustion engines (ICEs)
                    that generally run on fossil fuels like gas or diesel. EVs
                    use one or more electric motors powered by rechargeable
                    lithium-ion batteries, the same kinds of batteries that
                    power smartphones and laptops. And like electronic devices,
                    EVs plug into external power sources for charging. Other
                    types of batteries rely on regenerative braking for
                    charging, or generating electricity from the vehicle’s
                    frictional energy. In addition to being less polluting than
                    fuel engines, lithium-ion batteries often work more
                    efficiently. Many have a guaranteed life span of 8-10 years.
                  </p>

                  <p>
                    Because EVs do not rely on fossil fuels for power, they may
                    not have certain components that ICE vehicles do. For
                    instance, parts such as fuel lines, fuel tanks, and
                    tailpipes. This means that most EVs do not emit carbon
                    dioxide emissions (CO2), which helps reduce air pollution.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={6} className="p-img-col p-img-col-2">
              <img src="/images/13312002.png" alt="Eco-friendly Zipbolt" />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="p-info-container-dark">
        <Container>
          <Row>
            <Col sm={12} md={6} className="p-img-col p-img-col-3">
              <img src="/images/13312002.png" alt="Eco-friendly Zipbolt" />
            </Col>
            <Col sm={12} md={6} className="p-info-section-title">
              <span className="blue-highlighted-text">EV charging</span> is now
              easy as charging your{" "}
              <span className="blue-highlighted-text">Smartphone</span>.
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
