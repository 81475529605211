import React, { useState } from "react";
import { Container, Row, Col, Carousel, CarouselItem } from "react-bootstrap";
import "./Testimonial.css";

export default function Testimonial() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // const testimonials = [
  //   {
  //     content: "",
  //     author: " ",
  //   },
  //   {
  //     content: "",
  //     author: "",
  //   },
  // ];

  return (
    <>
      <Container className="testimonial-container">
        <Row className="testimonial-main-container">
          <Col className="testimonial-section">
            <Row className="testimonial-header">
              <Col className="testimonial-header-title">
                <h2 className="">
                  Few <span className="">Testimonials</span>
                </h2>
              </Col>
            </Row>
            <Row className="testimonial-subheader">
              <Col ClassName="testimonial-subheader-block">
                <h2 className="testimonial-subheader-text">
                  Why our Customers love us ❤
                </h2>
              </Col>
            </Row>
            <Row className="testimonial-content">
              <Col>
                <Carousel
                  interval={1000}
                  activeIndex={index}
                  onSelect={handleSelect}
                >
                  {/* {testimonials.map((c, index) => {
                    return (
                      <Carousel.Item interval={5000}>
                        <div
                          style={{
                            height: 500,
                            background: "white",
                            color: "black",
                          }}
                        >
                          <p>{c.content}</p>
                        </div>
                        <Carousel.Caption>
                          <p>{c.author}</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                    );
                  })} */}
                </Carousel>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
