import React from "react";
import "../../App.css";
import Cards from "../Cards";
import HeroSection from "../HeroSection";
import Footer from "../Footer";
import AboutUs from "../AboutUs";
import Clients from "../OurClients";
import Contact from "../ContactUs";
import BttButton from "../BttButton";
import WhatsappBtn from "../WhatsappBtn";
import { Container } from "react-bootstrap";
import Testimonial from "../Testimonial";

function Home() {
  return (
    <>
      <HeroSection />
      <AboutUs />
      <Cards />
      <Clients />
      <Testimonial />

      {/* Facts Section Code Start */}

      <Container fluid className="s-home-facts-c">
        <div className="s-home-facts">
          <div className="s-home-facts-heading">
            <p>
              Towards
              <span className="blue-highlighted-text"> Sustainable </span>
              Livelihoods And{" "}
              <span className="blue-highlighted-text">Clean Mobility</span>
            </p>
          </div>
          <div className="NumberSection">
            <div class="single">
              <div class="single-topic-bold" data-aos="zoom-out">
                <span className="blue-text">60</span>
                Lac+
              </div>
              <div class="desc"> Electric Kms Covered</div>
            </div>

            <div class="single">
              <div class="single-topic-bold" data-aos="zoom-out">
                <span className="blue-text">100</span>+
              </div>
              <div class="desc"> Swapping Stations</div>
            </div>
            <div class="single-last">
              <div class="single-topic-bold" data-aos="zoom-out">
                <span className="blue-text">INR 7 </span>Cr+
              </div>
              <div class="desc"> Driver Income Generated</div>
            </div>
          </div>
          {/* <hr className="facts-endline"></hr> */}
        </div>
      </Container>

      {/* Facts Section Code End */}

      <Contact />
      {/* <WhatsappBtn /> */}
    </>
  );
}

export default Home;
