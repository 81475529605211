import React from "react";
import Footer from "../Footer";
import CareerSec from "../CareerSec";
import Contact from "../ContactUs";

function Careers() {
  return (
    <>
      <CareerSec />
    </>
  );
}
export default Careers;
